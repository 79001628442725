import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import "./WhyPage.css";
import im1 from "../../img/11.jpg";
import im2 from "../../img/111.jpg";
import im3 from "../../img/1111.jpg";
import im4 from "../../img/11111.jpg";
import v1 from "../../img/video.mp4";
import Contact from "../contact/contact";

const WhyPage = () => {
  const PlayVideo = () => {
    const link = document.querySelector(".vid");
    link.removeAttribute("controls");
    link.play();
  };
  useEffect(() => {
    PlayVideo();
  }, []);
  return (
    <>
      <div id="about" className="heightsection about">
        <Fade direction="up" triggerOnce="false">
          <img style={{ width: "200px" }} src="logo.png" alt="" />
          <h2>Ste-elektro</h2>
        </Fade>
        <Fade direction="left" triggerOnce="false">
          <ul className="whyul">
            <li>
              <i
                className="fa-solid fa-chevron-right"
                style={{ color: "#674188" }}></i>{" "}
              Erfahrene Fachkräfte: Unser Team besteht aus qualifizierten
              Elektrikern mit umfangreicher Erfahrung
            </li>
            <li>
              <i
                className="fa-solid fa-chevron-right"
                style={{ color: "#674188" }}></i>
              Kundenzufriedenheit: Ihre Wünsche stehen bei uns an erster Stelle.
            </li>
            <li>
              <i
                className="fa-solid fa-chevron-right"
                style={{ color: "#674188" }}></i>{" "}
              Innovative Technologien: Wir setzen auf die neuesten Entwicklungen
              in der Elektrobranche.
            </li>
          </ul>
        </Fade>
      </div>
      <div class="img-galary">
        <div class="img-galary__coloumn img-galary__coloumn1">
          <img src={im1} alt="" />
        </div>
        <div class="img-galary__coloumn img-galary__coloumn1">
          <img src={im2} alt="" />
        </div>{" "}
        <div class="img-galary__coloumn img-galary__coloumn1">
          <img src={im3} alt="" />
        </div>{" "}
        <div class="img-galary__coloumn img-galary__coloumn1">
          <img src={im4} alt="" />
        </div>{" "}
      </div>
      <hr />
      <video loop autoPlay={true} muted className="vid d-block w-100">
        <source src={v1} />
      </video>
      <Contact />
    </>
  );
};

export default WhyPage;
