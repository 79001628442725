import React, { useState } from "react";
import "./Referenzen.css";
import { Carousel } from "react-bootstrap";
import img1 from "../../img/a1.jpg";
import img2 from "../../img/a2.jpg";
import img3 from "../../img/b1.jpg";
import img4 from "../../img/b2.jpg";
import img5 from "../../img/c1.jpg";
import img6 from "../../img/c2.jpg";
const Referenzen = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="Referenzen">
      <h1>Referenzen Erneuerbare Energien</h1>
      <div className="contentre">
        <div className="textref">
          <h2>Photovoltaik-Anlage 18 KW</h2>
          <h5>System: Kostal </h5>
          <h5>Module: Luxor 330W</h5>
          <h5>Batterie: BYD 10 kWh</h5>
          <h5>Wallbox: Keba</h5>
        </div>
        <div className="sliderref">
          <div className="s">
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              className="slider"
              interval={1500}>
              <Carousel.Item>
                <div className="containImg">
                  <img className="d-block" src={img1} alt="First slide" />
                  <img className="d-block" src={img2} alt="First slide" />
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="containImg">
                  <img className="d-block" src={img1} alt="First slide" />
                  <img className="d-block" src={img2} alt="First slide" />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="contentre">
        <div className="textref">
          <h2>Photovoltaik-Anlage 18 KW</h2>
          <h5>System: Kostal </h5>
          <h5>Module: Luxor 330W</h5>
          <h5>Batterie: BYD 10 kWh</h5>
          <h5>Wallbox: Keba</h5>
        </div>
        <div className="sliderref">
          <div className="s">
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              className="slider"
              interval={2500}>
              <Carousel.Item>
                <div className="containImg">
                  <img className="d-block" src={img3} alt="First slide" />
                  <img className="d-block" src={img4} alt="First slide" />
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="containImg">
                  <img className="d-block" src={img3} alt="First slide" />
                  <img className="d-block" src={img4} alt="First slide" />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

      <div className="contentre">
        <div className="textref">
          <h2>Photovoltaik-Anlage 18 KW</h2>
          <h5>System: Kostal </h5>
          <h5>Module: Luxor 330W</h5>
          <h5>Batterie: BYD 10 kWh</h5>
          <h5>Wallbox: Keba</h5>
        </div>
        <div className="sliderref">
          <div className="s">
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              className="slider"
              interval={2000}>
              <Carousel.Item>
                <div className="containImg">
                  <img className="d-block" src={img5} alt="First slide" />
                  <img className="d-block" src={img6} alt="First slide" />
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="containImg">
                  <img className="d-block" src={img5} alt="First slide" />
                  <img className="d-block" src={img6} alt="First slide" />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referenzen;
