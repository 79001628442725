import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/layout/layout";
import HomePage from "./components/HomePage/HomePage";
import WhyPage from "./components/WhyPage/WhyPage";
import Referenzen from "./components/Referenzen/Referenzen";
import LeistungenPage from "./components/LeistungenPage/LeistungenPage";
import Terminanfrage from "./components/Terminanfrage/Terminanfrage";
import MainModal from "./components/Modal/Modal";
import Datenschutz from "./components/Datenschutz/Datenschutz";
function App() {
  return (
    <div className="App">
      <Layout>
        <MainModal Content/>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/Referenzen" element={<Referenzen />} />
          <Route path="/Leistungen" element={<LeistungenPage />} />
          <Route path="/Überuns" element={<WhyPage />} />
          <Route path="/Terminanfrage" element={<Terminanfrage />} />
          <Route path="/Datenschutz" element={<Datenschutz />} />

        </Routes>
      </Layout>
    </div>
  );
}

export default App;
