import React from "react";
import SomeLink from "../SomeLink/SomeLink";

const LeistungenPage = () => {
  return (
    <div>
      <SomeLink />
    </div>
  );
};

export default LeistungenPage;
