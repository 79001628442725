import React, { useEffect } from "react";
import img from "../../img/undraw_Contact_us_re_4qqt.png";
import "./contact.css";
const Contact = () => {
  // const PlayVideo = () => {
  //   const link = document.querySelector(".vid");
  //   link.addEventListener("loadeddata", () => {
  //     link.removeAttribute("controls");
  //     link.play();
  //   });
  // };
  // useEffect(() => {
  //   PlayVideo();
  // }, []);
  return (
    <div id="contact" className="conatact">
      <div className="heightsection">
        <section className="info">
          {/* <h3 className="tit text-center">اهم المميزات </h3> */}
          <div className="container">
            <div className="row">
              <div className="col-md-6 contentt">
                <h2 className="text-uppercase">
                  Kontaktieren Sie uns noch heute!
                </h2>
                <a
                  style={{ textDecoration: "none" }}
                  href="https://wa.me/message/Info@ste-elektro">
                  {" "}
                  <p>📞 +491635241766</p>
                </a>
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:Info@ste-elektro@gmail.com">
                  <p>📧 Info@ste-elektro.de</p>
                </a>
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:Info@ste-elektro@gmail.com">
                  <p>🌐 www.Ste-elektro.de</p>
                </a>
                <div className="cont2">
                  <div style={{ textAlign: "justify" }}>
                    <a href="https://www.facebook.co">
                      {" "}
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com">
                      <i
                        style={{ color: "#833ab4" }}
                        className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="mailto:dg@gmail.com">
                      <i
                        style={{ color: "#c71610" }}
                        className="fa-regular fa-envelope"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {/* <video
                style={{ width: "400px" }}
                loop
                autoPlay
                muted
                className="vid">
                <source src={v} />
              </video>              */}
                <img src={img} className="img-responsive" alt="feature img" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
