import React from "react";
import "./services.css";
import img1 from "../../img/1.jpg";
import img2 from "../../img/2.jpg";
import img3 from "../../img/iStock-1015452484-2 (1).jpg";
import img4 from "../../img/smart-home-neurio-7.webp";

const Services = () => {

  return (
    <div className="serv">
      {/* <h3
        className="text-center"
        style={{ color: "#071c43", fontWeight: "bold" }}>
        Freie Stellen
      </h3> */}
      {/* <p className="text-center">
        Lust auf Handwerk? Begeistert von moderner Gebäudetechnik? Gerne
        partnerschaftlich in einem starken Team arbeiten?
      </p> */}
      <div id="service" className="heightsection service">
        <div className="cardd">
          <img src={img1} alt="" />
          <div style={{ lineHeight: 1.7 }}>
            {/* <h4>Freie Stellen</h4> */}
            <p>
              Elektroinstallationen: Von der Planung bis zur Ausführung – wir
              kümmern uns um alles
            </p>
          </div>
        </div>
        <div className="cardd">
          <img src={img2} alt="" />
          <div style={{ lineHeight: 1.7 }}>
            {/* <h4>Freie Stellen</h4> */}
            <p>
              Energieberatung: Optimieren Sie Ihren Energieverbrauch und senken
              Sie Ihre Kosten.
            </p>
          </div>
        </div>
        <div className="cardd">
          <img src={img3} alt="" />
          <div style={{ lineHeight: 1.7 }}>
            {/* <h4>Freie Stellen</h4> */}
            <p>
              Smart Home Lösungen: Machen Sie Ihr Zuhause intelligenter und
              effizienter
            </p>
          </div>
        </div>
        <div className="cardd">
          <img src={img4} alt="" />
          <div style={{ lineHeight: 1.7 }}>
            {/* <h4>Freie Stellen</h4> */}
            <p>
              . Beleuchtungstechnik: Kreative und energieeffiziente
              Beleuchtungslösungen für jeden Raum Verschiedene Arten von 
              Photovoltaik
            </p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Services;
