import React from "react";
import "./layout.css";
import Footer from "../footer/footer";
import Navbarr from "../navbar/navbar";
const Layout = ({ children, userData }) => {
  return (
    <div className={`layout`}>
      <Navbarr />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
