import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h2 className="mb-3">Kontakt</h2>
            <ul>
              <li>
                <a href="index.html">
                  <i className="fa-solid fa-chevron-right"></i> Telefon:
                  +491635241766
                </a>
              </li>
              <li>
                <a href="category.html">
                  <i className="fa-solid fa-chevron-right"></i> E-Mail:
                  info@ste-elektro.de
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h2 className="mb-3">Öffnungszeiten</h2>

            <p>Montag – Donnerstag: 8.00 – 17.00 Uhr</p>
            <p>Freitag: 8.00 – 15.00 Uhr</p>
          </div>
          <div className="col-md-4">
            <h2>Social</h2>
            <a href="#">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>

            <a href="#">
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="text-center">
        Alle Rechte bleiben Ste-elektro vorbehalten &copy;
      </div>
    </div>
  );
};

export default Footer;
