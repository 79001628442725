import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Terminanfrage.css";
import { Container, Spinner } from "react-bootstrap";
const Terminanfrage = () => {
  const [Datum, setDatum] = useState("");
  const [Uhrzeit, setUhrzeit] = useState("");
  const [Betreff, setBetreff] = useState("");
  const [Postleitzahl, setPostleitzahl] = useState("");
  const [Stadt, setStadt] = useState("");
  const [Straße, setStraße] = useState("");
  const [Hausnummer, setHausnummer] = useState("");
  const [Vorname, setVorname] = useState("");
  const [Nachname, setNachname] = useState("");
  const [E_Mail_Adresse, setE_Mail_Adresse] = useState("");
  const [Telefonnummer, setTelefonnummer] = useState("");
  const [Nachricht, setNachricht] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Checkk, setCheckk] = useState(false);
  const notify = (msg, type) => {
    if (type === "warn") toast.warn(msg);
    else if (type === "success") toast.success(msg);
    else if (type === "error") toast.error(msg);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (
      Datum === "" ||
      Uhrzeit === "" ||
      Betreff === "" ||
      Postleitzahl === "" ||
      Stadt === "" ||
      Vorname === "" ||
      Nachname === "" ||
      E_Mail_Adresse === "" ||
      Checkk === false
    ) {
      notify(
        "Bitte vervollständigen Sie die erforderlichen Informationen",
        "error"
      );
      return;
    }
    const data = {
      datum: Datum,
      uhrzeit: Uhrzeit,
      betreff: Betreff,
      postleitzahl: Postleitzahl,
      stadt: Stadt,
      straße: Straße,
      hausnummer: Hausnummer,
      vorname: Vorname,
      nachname: Nachname,
      e_mail_adresse: E_Mail_Adresse,
      telefonnummer: Telefonnummer,
      nachricht: Nachricht,
    };
    try {
      setLoading(true);
      const response = await fetch("http://51.20.105.2:3000/send-mail/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add other headers like Authorization if necessary
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        notify("Die Daten wurden erfolgreich gesendet", "success");
        setLoading(false);
        setDatum("");
        setUhrzeit("");
        setBetreff("");
        setPostleitzahl("");
        setStadt("");
        setStraße("");
        setHausnummer("");
        setVorname("");
        setNachname("");
        setE_Mail_Adresse("");
        setTelefonnummer("");
        setNachricht("");
        setLoading("");
        setCheckk(false);
      } else {
        notify(
          "Es ist ein Fehler aufgetreten. Versuchen Sie es erneut",
          "error"
        );
        setLoading(false);
        setDatum("");
        setUhrzeit("");
        setBetreff("");
        setPostleitzahl("");
        setStadt("");
        setStraße("");
        setHausnummer("");
        setVorname("");
        setNachname("");
        setE_Mail_Adresse("");
        setTelefonnummer("");
        setNachricht("");
        setLoading("");
        setCheckk(false);
      }
    } catch (error) {
      notify("Es ist ein Fehler aufgetreten. Versuchen Sie es erneut", "error");
      setLoading(false);
      setDatum("");
      setUhrzeit("");
      setBetreff("");
      setPostleitzahl("");
      setStadt("");
      setStraße("");
      setHausnummer("");
      setVorname("");
      setNachname("");
      setE_Mail_Adresse("");
      setTelefonnummer("");
      setNachricht("");
      setLoading("");
      setCheckk(false);
    }
  };
  return (
    <div className="Terminanfrage">
      <Container>
        <form>
          <h1 className="text-center mb-3" style={{ fontWeight: "bold" }}>
            Terminanfrage
          </h1>
          <p className="text-center">
            Wir bieten Ihnen mehrere Möglichkeiten einen Termin mit uns zu
            vereinbaren. Nutzen Sie unsere E-Mail-Adresse, rufen Sie uns an oder
            nutzen Sie unser Kontaktformular für Terminvereinbarungen. Geben Sie
            Ihre Terminwünsche in unserem Formular ein, wir rufen Sie dann mit
            passenden Terminvorschlägen zurück.
          </p>
          <h5>*Pflichtfelder</h5>
          <div className="inpts">
            <div className="inp">
              <label>
                Datum
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                required
                type="date"
                placeholder="Datum"
                value={Datum}
                onChange={(e) => setDatum(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>
                Uhrzeit<span style={{ color: "red" }}>*</span>
              </label>
              <input
                required
                type="time"
                placeholder="Uhrzeit"
                value={Uhrzeit}
                onChange={(e) => setUhrzeit(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>
                Betreff<span style={{ color: "red" }}>*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Betreff"
                value={Betreff}
                onChange={(e) => setBetreff(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>
                Postleitzahl<span style={{ color: "red" }}>*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Postleitzahl"
                value={Postleitzahl}
                onChange={(e) => setPostleitzahl(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>
                Stadt<span style={{ color: "red" }}>*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Stadt"
                value={Stadt}
                onChange={(e) => setStadt(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>Straße</label>
              <input
                type="text"
                placeholder="Straße"
                value={Straße}
                onChange={(e) => setStraße(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>Hausnummer</label>
              <input
                type="number"
                placeholder="Hausnummer"
                value={Hausnummer}
                onChange={(e) => setHausnummer(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>
                Vorname<span style={{ color: "red" }}>*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Vorname"
                value={Vorname}
                onChange={(e) => setVorname(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>
                Nachname<span style={{ color: "red" }}>*</span>
              </label>
              <input
                required
                type="text"
                placeholder="Nachname"
                value={Nachname}
                onChange={(e) => setNachname(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>
                E-Mail-Adresse<span style={{ color: "red" }}>*</span>
              </label>
              <input
                required
                type="email"
                placeholder="E-Mail-Adresse"
                value={E_Mail_Adresse}
                onChange={(e) => setE_Mail_Adresse(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>Telefonnummer</label>
              <input
                type="text"
                placeholder="Telefonnummer"
                value={Telefonnummer}
                onChange={(e) => setTelefonnummer(e.target.value)}
              />
            </div>
            <div className="inp">
              <label>Nachricht</label>
              <input
                type="text"
                placeholder="Nachricht"
                value={Nachricht}
                onChange={(e) => setNachricht(e.target.value)}
              />
            </div>
          </div>
          <p className="mb-3">Datenschutzerklärung</p>
          <input
            type="checkbox"
            style={{ width: "15px", height: "15px", marginRight: "1px" }}
            value={Checkk}
            onChange={(e) => setCheckk(e.target.checked)}
            checked={Checkk}
          />
          <span style={{ color: "red" }}>*</span>
          Ich habe die Datenschutzerklärung zur Kenntnis genommen. Ich stimme
          zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage
          elektronisch erhoben und gespeichert werden. Hinweis: Sie können Ihre
          Einwilligung jederzeit für die Zukunft per E-Mail an uns widerrufen.
          {Loading ? (
            <button className="btnSubmit">
              <Spinner />
            </button>
          ) : (
            <button className="btnSubmit" onClick={sendEmail}>
              <i className="fa-regular fa-envelope"></i> TERMIN ANFRAGEN
            </button>
          )}
        </form>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default Terminanfrage;
