import React from "react";
import "./help.css";
import img from "../../img/undraw_Questions_re_1fy7.png";
import { Fade } from "react-awesome-reveal";

const Help = () => {
  return (
    <div id="help" className="help">
      <div className="heightsection">
        <section className="info">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img src={img} className="img-responsive" alt="feature img" />
              </div>
              <div className="col-md-6 contentt">
                <Fade direction="left" triggerOnce="false">
                  <h2 className="text-uppercase">Warum Ste-elektro?</h2>
                  <p>
                    <ul>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i> Erfahrene
                        Fachkräfte: Unser Team besteht aus qualifizierten
                        Elektrikern mit umfangreicher Erfahrung.
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i>
                        Kundenzufriedenheit: Ihre Wünsche stehen bei uns an
                        erster Stelle.
                      </li>
                      <li>
                        <i className="fa-solid fa-chevron-right"></i> Innovative
                        Technologien: Wir setzen auf die neuesten Entwicklungen
                        in der Elektrobranche.
                      </li>
                    </ul>
                  </p>
                </Fade>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Help;
