import React, { useState } from "react";
import "./SomeLink.css";
import MainModal from "../Modal/Modal";
const SomeLink = () => {
  const [show, setShow] = useState(false);
  const [Content, setContent] = useState(false);

  const handleClose = () => {
    setContent("")
    setShow(false)
  
  };
  const handleShow = (content) => {
    setContent(content);
    setShow(true);
  };
  return (
    <div className="SomeLink">
      <h2>UNSERE LEISTUNGEN IM ÜBERBLICK</h2>
      <div className="SomeConent">
        <h3>Beleuchtung</h3>
        <p
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() =>
            handleShow(`Gerne! Hier ist eine mögliche Erklärung für Ihre Kunden zu den Leistungen im Bereich Beleuchtung:

Unsere Leistungen im Bereich Beleuchtung

Wir bieten Ihnen ein umfassendes Sortiment an Beleuchtungslösungen, die sowohl funktionale als auch ästhetische Anforderungen erfüllen. Unsere Dienstleistungen umfassen:

Beratung und Planung: Wir helfen Ihnen, die richtige Beleuchtung für Ihre Bedürfnisse zu finden. Dabei berücksichtigen wir Faktoren wie Raumgröße, Nutzung und gewünschte Atmosphäre.

Individuelle Lichtkonzepte: Jedes Projekt ist einzigartig. Wir entwickeln maßgeschneiderte Lichtkonzepte, die auf Ihre spezifischen Anforderungen und Wünsche abgestimmt sind.

Installation: Unser erfahrenes Team kümmert sich um die fachgerechte Installation Ihrer Beleuchtungssysteme, sodass Sie sich um nichts kümmern müssen.

Energieeffiziente Lösungen: Wir bieten Ihnen moderne, energieeffiziente Beleuchtungsoptionen, die nicht nur die Umwelt schonen, sondern auch Ihre Energiekosten senken.

Wartung und Service: Auch nach der Installation stehen wir Ihnen zur Seite. Wir bieten regelmäßige Wartung und schnelle Reparaturservices, um sicherzustellen, dass Ihre Beleuchtung stets optimal funktioniert.

Smart Home Integration: Nutzen Sie die Vorteile der modernen Technologie mit intelligenten Beleuchtungssystemen, die sich bequem über Ihr Smartphone oder Sprachassistenten steuern lassen.

Sonderanfertigungen: Für spezielle Projekte oder individuelle Wünsche bieten wir auch maßgeschneiderte Lösungen an.

Wenn Sie weitere Informationen benötigen oder Fragen haben, zögern Sie nicht, uns zu kontaktieren! Wir freuen uns darauf, Ihnen zu helfen!`)
          }>
          MEHR INFORMATION
        </p>
      </div>
      <div className="SomeConent">
        <h3>Elektroinstallation</h3>
        <p
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() =>
            handleShow(`Natürlich! Hier ist eine detaillierte Erklärung für Ihre Kunden zu den Leistungen im Bereich Beleuchtung:

Unsere Leistungen im Bereich Beleuchtung

Wir bieten Ihnen ein breites Spektrum an Beleuchtungslösungen, die sowohl funktionale als auch ästhetische Aspekte berücksichtigen. Unsere Dienstleistungen umfassen:

Beratung und Planung: Wir beginnen mit einer umfassenden Beratung, um Ihre individuellen Bedürfnisse und Wünsche zu verstehen. Gemeinsam entwickeln wir ein Lichtkonzept, das auf die spezifischen Gegebenheiten Ihres Raumes abgestimmt ist.

Individuelle Lichtgestaltung: Jedes Projekt ist einzigartig. Wir erstellen maßgeschneiderte Lichtgestaltungen, die sowohl die Funktionalität als auch die Atmosphäre Ihrer Räume verbessern. Dabei berücksichtigen wir verschiedene Lichtquellen, Farbtemperaturen und Lichtverteilungen.

Installation von Beleuchtungssystemen: Unser erfahrenes Team übernimmt die fachgerechte Installation aller Arten von Beleuchtung – von Deckenleuchten über Wandleuchten bis hin zu Außenbeleuchtung. Wir sorgen dafür, dass alles sicher und effizient installiert wird.

Energieeffiziente Lösungen: Wir bieten Ihnen moderne, energieeffiziente Beleuchtungsoptionen wie LED-Technologie an, die nicht nur umweltfreundlich sind, sondern auch Ihre Energiekosten senken können.

Smart Lighting: Nutzen Sie innovative Smart-Home-Technologien mit intelligenten Beleuchtungssystemen, die sich bequem über Ihr Smartphone oder Sprachassistenten steuern lassen. So können Sie Lichtstimmungen nach Ihren Wünschen anpassen und Energie sparen.

Wartung und Reparatur: Auch nach der Installation stehen wir Ihnen zur Verfügung. Wir bieten regelmäßige Wartungsdienste sowie schnelle Reparaturen an, um sicherzustellen, dass Ihre Beleuchtung stets optimal funktioniert.

Sonderanfertigungen: Für spezielle Anforderungen oder individuelle Wünsche bieten wir auch maßgeschneiderte Lösungen an, sei es für gewerbliche Objekte oder private Wohnräume.

Lichtplanung für Außenbereiche: Wir gestalten auch Außenbeleuchtungen für Gärten, Terrassen und Eingangsbereiche, um Sicherheit und Ästhetik zu vereinen.

Wenn Sie Fragen haben oder mehr Informationen wünschen, zögern Sie nicht, uns zu kontaktieren! Wir freuen uns darauf, Ihnen bei der Umsetzung Ihrer Beleuchtungsprojekte zu helfen!`)
          }>
          MEHR INFORMATION
        </p>
      </div>
      <div className="SomeConent">
        <h3>Industrie</h3>
        <p
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() =>
            handleShow(`Gerne! Hier ist eine mögliche Erklärung für Ihre Kunden zu den Leistungen im Bereich Industrie:

Unsere Leistungen im Bereich Industrie

Wir bieten Ihnen umfassende Lösungen und Dienstleistungen, die speziell auf die Anforderungen der Industrie zugeschnitten sind. Unser Ziel ist es, Ihre Produktionsprozesse zu optimieren und die Effizienz Ihrer Anlagen zu steigern. Unsere Leistungen umfassen:

Industrielle Elektroinstallation: Wir übernehmen die Planung und Installation von elektrischen Anlagen in industriellen Umgebungen, einschließlich Maschinenanschlüssen, Schaltanlagen und Steuerungssystemen.

Automatisierungstechnik: Wir unterstützen Sie bei der Automatisierung Ihrer Produktionsprozesse durch moderne Steuerungstechnik und Programmierung von SPS (Speicherprogrammierbare Steuerungen). Dies erhöht die Effizienz und reduziert Fehlerquellen.

Wartung und Instandhaltung: Regelmäßige Wartung ist entscheidend für den reibungslosen Betrieb Ihrer Anlagen. Wir bieten maßgeschneiderte Wartungsverträge an, um Ausfallzeiten zu minimieren und die Lebensdauer Ihrer Maschinen zu verlängern.

Energieeffizienzlösungen: Wir analysieren Ihre Energieverbräuche und entwickeln Strategien zur Reduzierung der Energiekosten durch den Einsatz energieeffizienter Technologien und Systeme.

Sicherheitslösungen: Die Sicherheit am Arbeitsplatz hat höchste Priorität. Wir implementieren Sicherheitskonzepte, die den aktuellen Vorschriften entsprechen, um Unfälle zu vermeiden und ein sicheres Arbeitsumfeld zu gewährleisten.

Notfallservice: Im Falle eines technischen Problems stehen wir Ihnen mit unserem Notdienst rund um die Uhr zur Verfügung, um schnellstmöglich Lösungen zu finden und Ausfallzeiten zu minimieren.

Beratung und Schulung: Wir bieten Schulungen für Ihr Personal an, um sicherzustellen, dass alle Mitarbeiter mit den neuen Systemen vertraut sind und diese effizient nutzen können.

Projektmanagement: Von der Planung bis zur Umsetzung – wir begleiten Ihr Projekt in allen Phasen, um sicherzustellen, dass alles termingerecht und innerhalb des Budgets abgeschlossen wird.

Wenn Sie weitere Informationen benötigen oder Fragen haben, zögern Sie nicht, uns zu kontaktieren! Wir freuen uns darauf, Ihnen bei Ihren industriellen Projekten zur Seite zu stehen!`)
          }>
          MEHR INFORMATION
        </p>
      </div>{" "}
      <div className="SomeConent">
        <h3>Intelligente Gebäudesteuerung</h3>
        <p
          style={{ color: "blue", cursor: "pointer" }}
          onClick={() =>
            handleShow(`Gerne! Hier ist eine Erklärung für Ihre Kunden zu den Leistungen im Bereich intelligente Gebäudesteuerung:

Unsere Leistungen im Bereich Intelligente Gebäudesteuerung

Die intelligente Gebäudesteuerung revolutioniert die Art und Weise, wie wir unsere Gebäude nutzen und verwalten. Mit modernen Technologien schaffen wir ein komfortables, sicheres und energieeffizientes Umfeld. Unsere Dienstleistungen umfassen:

Beratung und Planung: Wir analysieren Ihre individuellen Bedürfnisse und entwickeln ein maßgeschneidertes Konzept für die intelligente Steuerung Ihres Gebäudes. Dabei berücksichtigen wir sowohl technische als auch wirtschaftliche Aspekte.

Integration von Smart Home-Technologien: Wir integrieren verschiedene Systeme wie Beleuchtung, Heizung, Klimaanlage, Sicherheitstechnik und Jalousien in eine zentrale Steuerung. So können Sie alle Funktionen bequem über Ihr Smartphone oder Tablet steuern.

Automatisierung von Abläufen: Durch die Automatisierung von alltäglichen Abläufen – wie das automatische Dimmen der Lichter bei Sonnenuntergang oder das Anpassen der Heizung je nach Anwesenheit – erhöhen wir den Komfort und die Energieeffizienz Ihres Gebäudes.

Energie-Management-Systeme: Wir implementieren Systeme zur Überwachung und Optimierung des Energieverbrauchs. So können Sie nicht nur Kosten sparen, sondern auch aktiv zur Reduzierung des CO2-Ausstoßes beitragen.

Sicherheitslösungen: Intelligente Gebäudesteuerung umfasst auch Sicherheitsfunktionen wie Überwachungskameras, Alarmanlagen und Zutrittskontrollen, die zentral gesteuert werden können, um ein hohes Maß an Sicherheit zu gewährleisten.

Fernzugriff und -überwachung: Mit unseren Lösungen haben Sie jederzeit Zugriff auf Ihr Gebäude – egal wo Sie sich befinden. Über mobile Apps können Sie Einstellungen anpassen, den Status Ihrer Systeme überprüfen oder Benachrichtigungen erhalten.

Wartung und Support: Auch nach der Installation stehen wir Ihnen mit unserem Support-Team zur Verfügung. Wir bieten regelmäßige Wartungsdienste an, um sicherzustellen, dass Ihre Systeme stets optimal funktionieren.

Schulung für Nutzer: Um sicherzustellen, dass Sie alle Funktionen Ihrer intelligenten Gebäudesteuerung optimal nutzen können, bieten wir Schulungen für Sie und Ihr Personal an.

Wenn Sie Fragen haben oder mehr Informationen wünschen, zögern Sie nicht`)
          }>
          MEHR INFORMATION
        </p>
      </div>{" "}
      <div className="SomeConent">
        <h3>Kommunikation</h3>
        {/* <p style={{ color: "blue", cursor: "pointer" }}>MEHR INFORMATION</p> */}
      </div>{" "}
      <div className="SomeConent">
        <h3>Regenerative Energien</h3>
        {/* <p style={{ color: "blue", cursor: "pointer" }}>MEHR INFORMATION</p> */}
      </div>{" "}
      <div className="SomeConent">
        <h3>Service</h3>
        {/* <p style={{ color: "blue", cursor: "pointer" }}>MEHR INFORMATION</p> */}
      </div>
      <MainModal
        Content={Content}
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        setShow={setShow}
      />
    </div>
  );
};

export default SomeLink;
