import React from "react";
import Intro from "../intro/intro";
import Help from "../help/help";
import Contact from "../contact/contact";
import IntroHome from "../IntroHome/IntroHome";
import Services from "../services/services";
import Datereq from "../Datereq/Datereq";
import SomeLink from "../SomeLink/SomeLink";
const HomePage = () => {
  return (
    <>
      <IntroHome />
      <Intro />
      <Services />

      <Datereq />
      {/* <SomeLink /> */}
      <Help />
      {/* <Contact /> */}
    </>
  );
};

export default HomePage;
