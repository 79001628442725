import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import im1 from "../../img/bulb-2287759_1920.jpg";
import "./IntroHome.css";
import { Fade } from "react-awesome-reveal";
function IntroHome() {
  return (
    <div className="ss" id="home">
      <div className="overlay"></div>
      <img className="d-block" src={im1} alt="First slide" />
      <div className="slider-caption">
        <Fade direction="up" triggerOnce="false">
          <h1 className="mb-1">Wir freuen uns über Ihren Besuch und sind jederzeit für Sie da</h1>
        </Fade>
      </div>
    </div>
  );
}

export default IntroHome;
