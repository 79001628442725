import React, { useEffect } from "react";
import "./intro.css";
import img from "../../img/undraw_electricity_k2ft.png";
import { Fade } from "react-awesome-reveal";
const Intro = () => {
  // const PlayVideo = () => {
  //   const link = document.querySelector(".vid");
  //   link.addEventListener("loadeddata", () => {
  //     link.removeAttribute("controls");
  //     link.play();
  //   });
  // };
  // useEffect(() => {
  //   PlayVideo();
  // }, []);
  return (
    <div id="home" className="heightsection">
      <section className="info">
        {/* <h3 className="tit text-center">اهم المميزات </h3> */}
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <Fade direction="left" triggerOnce="false">
                <h2 className="text-uppercase">Ste-elektro</h2>
              </Fade>
              <Fade direction="left" triggerOnce="false">
                <p>
                  🔌 Sichere und moderne Elektroinstallationen für Ihr Zuhause
                  und Unternehmen! 🔌
                </p>
                <br />
                <p>
                  Suchen Sie einen zuverlässigen Partner für Elektro
                  Gebäudetechnik? Bei STE-ELEKTRO bieten wir Ihnen umfassende
                  Lösungen, die auf Ihre individuellen Bedürfnisse
                  zugeschnitten sind
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
