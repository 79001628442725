import React from "react";
import "./Datereq.css";
import { useNavigate } from "react-router-dom";
const Datereq = () => {
  const navigate = useNavigate();
  return (
    <div className="DATEREQ">
      <div className="divContent">
        <h2>Wunschtermin</h2>
        <p>Jetzt ganz einfach und bequem online Termine anfragen!</p>
        <button onClick={() => navigate("/Terminanfrage")}>
          Termin vereinbaren
        </button>
      </div>         
      <div className="divIcon">
        <i className="fa-solid fa-calendar-days"></i>
      </div>       
    </div>
  );
};

export default Datereq;
